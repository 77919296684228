<template lang="pug">
  .page-color.widget.widget--min-screen-size
    .page-color__inner
      .page-color__content
        .title DRESS CODE
        p.page-color__text Будемо вдячні, якщо ви підтримаєте#[br] кольорову гаму нашого весілля.#[br] Чорний, білий, бежевий.
        p.page-color__text Жінки в однотонних сукнях,#[br] чоловіки в класичних костюмах.#[br]
        p.page-color__text P.S. Вітаються чорні сукні у дівчат.
        .page-color__colors
          template(v-for="color in colors")
            .page-color__plate(:style="{ backgroundColor: color }")
      .page-color__slider
        hooper(
          infiniteScroll
          :settings="hooperSettings")
          slide
            img.slide-img(src="@/assets/img/dress-01.jpg")
          slide
            img.slide-img(
              src="@/assets/img/dress-12.jpg"
              @click="showVideo = true")
          slide
            img.slide-img(src="@/assets/img/dress-02.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-03.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-04.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-05.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-06.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-07.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-08.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-09.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-10.jpg")
          slide
            img.slide-img(src="@/assets/img/dress-11.jpg")
    made-by-invito
    .page-color__video(
      v-if="showVideo"
      @click="showVideo = false")
      v-spinner.page-color__spinner
      video(
        autoplay
        width="70%"
        @ended="showVideo = false")
        source(
          type="video/mp4"
          src="https://firebasestorage.googleapis.com/v0/b/my-invito.appspot.com/o/dress-code.mp4?alt=media&token=731448cb-8836-4bcd-af4b-dfe853ccdff9")
</template>

<script>
import MadeByInvito from '@/components/common/MadeByInvito'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
  name: 'Index',

  components: {
    MadeByInvito,
    Hooper,
    Slide,
    HooperNavigation
  },

  data: () => ({
    colors: [
      '#FFFFFF',
      '#F1E3CA',
      '#1D1D1D'
    ],
    hooperSettings: {
      itemsToShow: 3,
      autoPlay: true,
      playSpeed: 2500,
      transition: 700
    },
    showVideo: false
  })
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_animations.scss';
  @import '../../sass/abstracts/_variables.scss';

  .page-color {
    padding-top: 10rem;
    padding-bottom: 8rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .title {
      padding: 1rem 0;
      text-align: center;
      margin-bottom: 3rem;
      border-top: .1rem solid $color-primary;
      border-bottom: .1rem solid $color-primary;
    }

    &__inner {
      width: 100%;
      opacity: 0;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }

    &__content {
      width: 27rem;
      margin: 0 auto;
    }

    &__text {
      color: $color-primary;
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
      white-space: nowrap;
      margin-bottom: 1.8rem;
    }

    &__colors {
      display: flex;
      justify-content: center;
      margin-bottom: 3.2rem;
    }

    &__video {
      position: fixed;
      width: 100%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      top: 0;
      left: 0;
      z-index: 100;
      backdrop-filter: blur(15px);
      background-color: rgba($color-primary, .5);
      display: flex;
      align-items: center;
      justify-content: center;

      video {
        z-index: 10;
      }
    }

    &__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(.4);
      z-index: 0;
    }

    // &__slider {
    //   position: relative;
    //   margin: 0 auto;
    //   margin-top: -22.4rem;
    //   width: 90%;
    //   background-color: $color-white;
    //   z-index: 2;
    // }

    &__plate {
      position: relative;
      width: 3rem;
      height: 10rem;

      &:first-child {
        border: .1rem solid rgba($color-primary, .1);
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      &::after {
        content: "";
        opacity: .3;
        position: absolute;
        height: 100%;
        width: 100%;
        top: .5rem;
        left: .5rem;
        border: .1rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom, #939393, #4E4E4E 90%, $color-primary);
      }
    }

    .hooper-slide {
      padding: 0 1rem
    }

    .slider-control {
      width: 3.4rem;
    }
  }
</style>
